import { Box, Button, Typography, Grid, Paper, TextField, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import ResultCard from "./ResultCard";
import React from "react";



const Simulateur = ({ onNumSubmit, onCalculate }) => {
    const [results, setResults] = useState(null);
    const [Devise, setDevise] = React.useState('Euro');

    const handleChangeDevise = (event) => {
        setDevise(event.target.value);
    }
    const [controle, setControle] = useState(true);
    const myRef = useRef(null);


    useEffect(() => {
        setControle(false);
    }, [Devise])

    const handleClear = () => {
        setResults(null);
        const numberInputs = document.querySelectorAll("input[type='number']");

        numberInputs.forEach((input) => {
            input.value = "";
        });
    };

    const handleCalculate = () => {
        const currentPricesEuro = {
            businessStarter: 5.2,
            businessStandard: 10.4,
            businessPlus: 15.6,
            enterpriseFrontLine: 4.8,
            enterpriseEssential: 8.7,
            enterpriseStarter: 8.7,
            enterpriseStandard: 17.3,
            enterprisePlus: 26,
        };
        const flexPricesEuro = {
            businessStarter: 6.9,
            businessStandard: 13.8,
            businessPlus: 20.7,
            enterpriseFrontLine: 5.64,
            enterpriseEssential: 11.52,
            enterpriseStarter: 11.52,
            enterpriseStandard: 26.64,
            enterprisePlus: 34.63,
        };

        const currentPricesDollar = {
            businessStarter: 6,
            businessStandard: 12,
            businessPlus: 18,
            enterpriseFrontLine: 5.004,
            enterpriseEssential: 10,
            enterpriseStarter: 10,
            enterpriseStandard: 20,
            enterprisePlus: 30,
        };
        const flexPricesDollar = {
            businessStarter: 7.2,
            businessStandard: 14.4,
            businessPlus: 21.6,
            enterpriseFrontLine: 6,
            enterpriseEssential: 12,
            enterpriseStarter: 12,
            enterpriseStandard: 27.6,
            enterprisePlus: 36,
        };

        let currentPrices, flexPrices;
        if (Devise === 'Euro') {
            currentPrices = currentPricesEuro;
            flexPrices = flexPricesEuro;
        } else {
            currentPrices = currentPricesDollar;
            flexPrices = flexPricesDollar;
        }

        const values = {
            businessStarter: parseFloat(document.getElementById("business-starter").value) || 0,
            businessStandard: parseFloat(document.getElementById("business-standard").value) || 0,
            businessPlus: parseFloat(document.getElementById("business-plus").value) || 0,
            enterpriseFrontLine: parseFloat(document.getElementById("enterprise-frontline").value) || 0,
            enterpriseEssential: parseFloat(document.getElementById("enterprise-essential").value) || 0,
            enterpriseStarter: parseFloat(document.getElementById("enterprise-starter").value) || 0,
            enterpriseStandard: parseFloat(document.getElementById("enterprise-standard").value) || 0,
            enterprisePlus: parseFloat(document.getElementById("enterprise-plus").value) || 0,
        };




        const currentTotal = Object.entries(currentPrices).reduce(
            (sum, [plan, price]) => sum + values[plan] * price,
            0
        );
        const flexTotal = Object.entries(flexPrices).reduce(
            (sum, [plan, price]) => sum + values[plan] * price,
            0
        );
        const calcul = {
            current: currentTotal.toFixed(2),
            flex: flexTotal.toFixed(2),
            difference: (flexTotal - currentTotal).toFixed(2),
            increase: (((flexTotal - currentTotal) / currentTotal) * 100).toFixed(2),
        }
        setResults({
            totals: calcul
        });

        const date = new Date();


        const inputData = Object.entries(values)
            .filter(([plan, value]) => value !== 0)
            .reduce((acc, [plan, value]) => {
                acc[plan] = value;
                return acc;
            }, { date, Devise, ...calcul });

        console.log(inputData);
        onCalculate(inputData);


    };
    return (



        <Box sx={{
            overflow: 'hidden',
        }}>

            <Typography sx={{
                mb: 2,
                marginTop: '50px',

                fontSize: '1.5em',
                '@media (min-width:600px)': { fontSize: "2.5em" }, // xs

                textAlign: 'center',
                color: '#dc4d3f',
                fontFamily: 'Roboto',

            }}>
                <strong>Calculateur Google Workspace</strong>
            </Typography>
            <Typography sx={{
                mb: 2,
                fontSize: '1em',
                '@media (min-width:600px)': { fontSize: "1.5em" }, // xs

                textAlign: 'center',
                color: 'black',
                fontFamily: 'Roboto',

            }}>
                <strong>Quels changements pour vous ?         </strong>
            </Typography>

            <Grid container justifyContent="center" sx={{ margin: "auto" }}>
                <Grid item xs={12} md={10}>
                    <Paper sx={{ p: 2 }}>
                        <Grid container justifyContent="flex-end">
                            <FormControl sx={{ minWidth: 120 }} size="small">
                                <InputLabel id="demo-select-small">Devise</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={Devise}
                                    label="Devise"
                                    onChange={handleChangeDevise}
                                >
                                    <MenuItem value="Euro">Euro €</MenuItem>
                                    <MenuItem value="Dollar">Dollar $</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid container justifyContent="center" sx={{ margin: "auto" }} spacing={1}>
                            <Grid item xs={12} md={6}>
                                <h3>Business</h3>
                                <TextField
                                    type="number"
                                    label="Nombre de Starter ?"
                                    sx={{ width: "100%" }}
                                    InputProps={{
                                        inputProps: { style: { textAlign: "left" } },
                                        endAdornment: "utilisateurs",
                                    }}
                                    id="business-starter"
                                />
                                <Box sx={{ height: 16 }} />
                                <TextField
                                    type="number"
                                    label="Nombre de Standard ?"
                                    sx={{ width: "100%" }}
                                    InputProps={{
                                        inputProps: { style: { textAlign: "left" } },
                                        endAdornment: "utilisateurs",
                                    }}
                                    id="business-standard"
                                />
                                <Box sx={{ height: 16 }} />
                                <TextField
                                    type="number"
                                    label="Nombre de Business plus ?"
                                    sx={{ width: "100%" }}
                                    InputProps={{
                                        inputProps: { style: { textAlign: "left" } },
                                        endAdornment: "utilisateurs",
                                    }}
                                    id="business-plus"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <h3>Enterprise</h3>
                                <TextField
                                    type="number"
                                    label="Nombre de FrontLine ?"
                                    sx={{ width: "100%" }}
                                    InputProps={{
                                        inputProps: { style: { textAlign: "left" } },
                                        endAdornment: "utilisateurs",
                                    }}
                                    id="enterprise-frontline"></TextField>

                                <Box sx={{ height: 16 }} />
                                <TextField
                                    type="number"
                                    label="Nombre de Essential ?"
                                    sx={{ width: "100%" }}
                                    InputProps={{
                                        inputProps: { style: { textAlign: "left" } },
                                        endAdornment: "utilisateurs",
                                    }}
                                    id="enterprise-essential"
                                />
                                <Box sx={{ height: 16 }} />
                                <TextField
                                    type="number"
                                    label="Nombre de Starter ?"
                                    sx={{ width: "100%" }}
                                    InputProps={{
                                        inputProps: { style: { textAlign: "left" } },
                                        endAdornment: "utilisateurs",
                                    }}
                                    id="enterprise-starter"
                                />
                                <Box sx={{ height: 16 }} />
                                <TextField
                                    type="number"
                                    label="Nombre de Standard ?"
                                    sx={{ width: "100%" }}
                                    InputProps={{
                                        inputProps: { style: { textAlign: "left" } },
                                        endAdornment: "utilisateurs",
                                    }}
                                    id="enterprise-standard"
                                />
                                <Box sx={{ height: 16 }} />
                                <TextField
                                    type="number"
                                    label="Nombre de Enterprise plus ?"
                                    sx={{ width: "100%" }}
                                    InputProps={{
                                        inputProps: { style: { textAlign: "left" } },
                                        endAdornment: "utilisateurs",
                                    }}
                                    id="enterprise-plus"
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                            <Button variant="outlined" sx={{ mx: 1 }} color="success" onClick={handleClear}>
                                Clear
                            </Button>
                            <Button sx={{
                                mx: 1, bgcolor: '#0ECD2D', '&:hover': {
                                    bgcolor: '#0ECD2D'
                                },
                            }}
                                variant="contained"
                                onClick={() => {
                                    handleCalculate();
                                    setControle(true);
                                    myRef.current.scrollIntoView({ behavior: 'smooth' });

                                }}>
                                Calculer
                            </Button>
                        </Box>
                        <Box ref={myRef}>
                            {results && controle && results.totals.current[0] === '0' && (
                                <Typography sx={{
                                    mb: 2,
                                    marginTop: '50px',
                                    fontSize: '1em',
                                    '@media (min-width:600px)': { fontSize: "1.5em", lineHeight: '2', margin: '16 0' }, // xs
                                    textAlign: 'center',
                                    color: 'red',
                                    fontFamily: 'Roboto',
                                }}>Vous avez saisi 0 utilisateur(s) sur le calculateur.
                                </Typography>
                            )}
                            {results && controle && (
                                <ResultCard
                                    onNumSubmit={onNumSubmit}
                                    current={results.totals.current}
                                    flex={results.totals.flex}
                                    difference={results.totals.difference}
                                    increase={results.totals.increase}
                                    Devise={Devise}
                                />
                            )}
                        </Box>




                    </Paper>
                </Grid>
            </Grid>

        </Box>

    );
};

export default Simulateur;