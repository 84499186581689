import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { useState } from "react";
import CollecteEmail from "../src/Components/CollecteEmail";
import Simulateur from "./Components/Simulateur";


const firebaseConfig = {
  apiKey: "AIzaSyCvXyP9vi_hDL0IiUu3VbNwAQiKNjJ-LiA",
  authDomain: "ds-calculateur.firebaseapp.com",
  projectId: "ds-calculateur",
  storageBucket: "ds-calculateur.appspot.com",
  messagingSenderId: "1075899015671",
  appId: "1:1075899015671:web:781643015ad05339af0ef2",
  measurementId: "G-YB0C9RHQGN"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const App = () => {
  const [email, setEmail] = useState("");
  const [showSimulateur, setShowSimulateur] = useState(false);

  const handleEmailSubmit = async (newEmail) => {
    setEmail(newEmail);
    const currentDate = new Date();
    await addDoc(collection(db, "emails"), { date: currentDate, ...newEmail });
    newEmail.isChecked && setShowSimulateur(true);
  };

  const handleCalculate = async (inputData) => {
    await addDoc(collection(db, "simulations"), { ...email, ...inputData });
  };

  const handleCalculateWithNum = async (inputData) => {
    await addDoc(collection(db, "simulationsWithNum"), { ...email, ...inputData });
  };



  return (
    <>
      {showSimulateur ? (
        <Simulateur onCalculate={handleCalculate} onNumSubmit={handleCalculateWithNum} />
      ) : (
        <CollecteEmail onEmailSubmit={handleEmailSubmit} />
      )}
      {/*       <Simulateur onCalculate={handleCalculate} />
 */}

    </>
  );
};

export default App;
