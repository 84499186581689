import { Box, Button, TextField, Typography } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import GwsImage from '../assets/images/GWS.png'
import { BiCalculator } from 'react-icons/bi';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Container } from "@mui/system";

const CollectEmail = ({ onEmailSubmit }) => {
    const [email, setEmail] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const labelRef = useRef(null);


    const handleEmailChange = (event) => {
        const value = event.target.value;
        const validEmailRegex2 = /^(?!(noreply|norepl|norep|nore|nor|no))[\w-\.]+@\S+\.\S+$/;
        const validEmailRegex = /^[\w-\.]+@(gmail|gmai|gma|gm|g|yahoo|yaho|yah|ya|hotmail|hotmai|hotma|hot|outlook|outloo|outlo|icloud|aol|protonmail|zoho|mail|yandex)\.\S/;
        setEmail(value);

        setIsValidEmail((!(validEmailRegex.test(value)) && (validEmailRegex2.test(value))));
    };
    const handleCheckChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleButtonClick = () => {
        if (isValidEmail) {
            onEmailSubmit({ email, isChecked });
            !isChecked ? labelRef.current.style.color = "red" : labelRef.current.style.color = "black";

        }
    };

    useEffect(() => { if (isChecked) { labelRef.current.style.color = "black"; } }, [isChecked]);

    return (
        <Container>


            <Box sx={{
                height: '100vh',
                overflow: 'hidden',
            }}>

                <Typography sx={{
                    mb: 2,
                    marginTop: '50px',
                    fontSize: '1.5em',
                    '@media (min-width:600px)': { fontSize: "2em", textAlign: "center" }, // xs
                    textAlign: 'center',
                    fontFamily: 'Roboto',
                }}>

                    Calculateur Google Workspace
                </Typography>

                <Typography sx={{
                    mb: 2,
                    fontSize: '2em',
                    '@media (min-width:600px)': { fontSize: "3em" }, // xs

                    textAlign: 'center',
                    color: '#dc1919',
                    fontFamily: 'Roboto',
                }}>
                    <strong>Estimez vos coûts</strong>
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={GwsImage} alt="GWS" />
                </Box>
                <Typography sx={{
                    mb: 2,
                    fontSize: '1,5em',
                    '@media (min-width:600px)': { fontSize: "2em", textAlign: "center" }, // xs

                    textAlign: 'center',
                    color: 'black',
                    fontFamily: 'Roboto',
                }}>
                    <strong>Demandez l'accès au calculateur</strong>
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <TextField
                        sx={{
                            width: '600px',
                            '& fieldset': {
                                borderRadius: 30,
                            },
                        }}
                        label="Email"
                        type="email"
                        variant="outlined"
                        color="success"
                        value={email}
                        onChange={handleEmailChange}
                        error={!isValidEmail && email !== ""}
                        helperText={!isValidEmail && email !== "" && "Adresse e-mail invalide. Veuillez vérifier et réessayer, merci de saisier une adresse email professionnelle"}
                    />

                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                    <FormControlLabel
                        control={<Checkbox checked={isChecked} onChange={handleCheckChange} />}
                        label="RGPD, j'accepte de recevoir des emails de la part de Digital Salamander."
                        sx={{
                            mb: 1,
                            fontSize: '0.5em',
                        }}
                        ref={labelRef}
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                    <Button
                        variant="contained"
                        disabled={!isValidEmail}
                        onClick={handleButtonClick}
                        sx={{
                            bgcolor: '#0ECD2D',
                            borderRadius: '30px',
                            '&:hover': {
                                bgcolor: '#0ECD2D'
                            },
                            width: '600px',

                        }}
                    >
                        <BiCalculator /> {" " + ` J'accède immédiatement au calculateur`}
                    </Button>
                </Box>
            </Box>
        </Container>

    );
};

export default CollectEmail

