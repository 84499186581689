import React from 'react';
import { Dialog, DialogContent, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const ReservationConfirmationDialog = ({ isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <div style={{ marginTop: '2rem', textAlign: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ width: '4rem', height: '4rem', borderRadius: '50%', border: '3px solid #48bb78', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '1rem' }}>
              <CheckIcon style={{ fontSize: '3rem', color: '#48bb78' }} />
            </div>
            <div style={{ fontSize: '1rem', fontWeight: 'bold', textTransform: 'uppercase', color: '#48bb78', marginBottom: '1rem', width: '100%' }}>
              Votre demande a bien été prise en compte
            </div>
            <div style={{ fontSize: '0.8rem', fontWeight: 'bold', color: '#4a5568', marginBottom: '1rem' }}>
              Un conseiller vous contactera dans les prochaines 72 heures

            </div>
            <Button
              variant="outlined"
              color="primary"
              onClick={onClose}
            >
              Fermer
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ReservationConfirmationDialog;
