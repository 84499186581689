import React, { useEffect } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import Digitalsalamander from '../assets/images/Digitalsalamander.png'
import { useState } from 'react';
import ReservationConfirmationDialog from './ReservationConfirmationDialog';


const ResultCard = ({ onNumSubmit, current, flex, difference, increase, Devise }) => {

    const [showConfirmation, setShowConfirmation] = useState(false);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };


    useEffect(() => {
        window.scrollTo({ top: 2000, behavior: 'smooth' }); // scroll vers le haut de la page

    }, []);

    const handleCloseConfirmation = () => {
        setShowConfirmation(false);
        setPhoneNumber('');
        setFormSubmitted(false);

        window.location.href = 'https://www.digitalsalamander.com/2022/06/02/pourquoi-passer-par-un-revendeur-certifie';

    };

    const handleSubmit = () => {
        const currentDate = new Date();
        onNumSubmit({ phoneNumber: phoneNumber, date: currentDate });
        setShowConfirmation(true);
        console.log('ShowConfirmation est affiché')

    };

    const handleFormSubmit = () => {
        if (phoneNumber.trim() === '') {
            setFormSubmitted(true);
            console.log('Numero de telephone : Numero vide')
        } else if (!/^\d{10,}$/.test(phoneNumber.trim())) {
            setFormSubmitted(true);
            console.log('Numero de telephone : doit contenir 10 chiffres')

        } else {
            setFormSubmitted(false);
            handleSubmit();
            console.log('Numero de telephone : le format est bon')

        }
    };

    return (
        <>



            <Box sx={{ my: 4, display: "flex", flexDirection: ["column", "column", "row"], justifyContent: "space-between", alignItems: "center" }}>
                <div sx={{ display: "flex", flexDirection: "column", alignItems: ["flex-start", "flex-start", "center"], mb: [2, 2, 0] }}>
                    <strong>Total prix actuel en 2022:</strong>{" "}
                    {current} {Devise === 'Euro' ? '€' : '$'}
                </div>
                <div sx={{ display: "flex", flexDirection: "column", alignItems: ["flex-start", "flex-start", "center"] }}>
                    <strong>Total prix Flex à partir d'avril 2023:</strong>{" "}
                    {flex} {Devise === 'Euro' ? '€' : '$'}
                </div>
                <div sx={{ display: "flex", flexDirection: "column", alignItems: ["flex-start", "flex-start", "center"], mb: [2, 2, 0], ml: [0, 0, 4] }}>
                    <strong>Différence:</strong> {difference} {Devise === 'Euro' ? '€' : '$'}
                </div>
                <div sx={{ display: "flex", flexDirection: "column", alignItems: ["flex-start", "flex-start", "center"] }}>
                    <strong>Augmentation:</strong> {increase === "NaN" ? 0 : increase}%
                </div>
            </Box>

            <Typography sx={{
                mb: 2,
                marginTop: '50px',

                fontSize: '1em',
                '@media (min-width:600px)': { fontSize: "1.5em", lineHeight: '2', margin: '16 0' }, // xs

                textAlign: 'center',
                color: 'black',
                fontFamily: 'Roboto',

            }}>
                Si vous ne faites rien, vous risquez de perdre {difference + " " + Devise} par mois soit {(difference * 12).toFixed(2) + " " + Devise} par an !
                <br />

                Cela représente une perte de {increase === "NaN" ? 0 : increase}% sur votre budget…
                <Box sx={{ display: 'flex', justifyContent: 'center', margin: '16px 0' }}>
                    <img src={Digitalsalamander} alt="Digitalsalamander" />
                </Box>


                Mais rassurez-vous, en tant que revendeur Google certifié,

                <br />

                Digital Salamander a la solution pour garantir le maintien de vos tarifs actuels.

                <br />
                Ne laissez pas la hausse des tarifs de Google Workspace affecter {increase === "NaN" ? 0 : increase}% de votre budget…

                <br />


                Demandez à être rappelé et profitez des conseils offerts et personnalisés d’un de nos experts.

                <br />

            </Typography>
            <Box sx={{ margin: '16px 0' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <TextField sx={{
                        mb: 2,
                        width: '500px',

                    }}
                        label="Numéro de téléphone [10 chiffres]"
                        type="number"
                        variant="outlined"
                        size="small"
                        color="success"
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        error={formSubmitted && phoneNumber.trim() === ''}
                        helperText={formSubmitted && phoneNumber.trim() === '' ? 'Le numéro de téléphone ne peut pas être vide' : formSubmitted && !/^\d{10,}$/.test(phoneNumber.trim()) ? 'Le numéro de téléphone doit contenir 10 chiffres' : ''}
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                    <Button variant="contained" color="primary" onClick={handleFormSubmit}
                        sx={{
                            bgcolor: '#23A455',
                            width: '500px',

                            '&:hover': {
                                bgcolor: '#0ECD2D'
                            },

                        }}>
                        Je veux être contacté par un conseiller
                    </Button>
                </Box>

            </Box>
            <Typography sx={{
                mb: 2,
                fontSize: '1em',
                lineHeight: '1.5',
                '@media (min-width:600px)': { fontSize: "1.5em" }, // xs

                textAlign: 'center',
                color: 'black',
                fontFamily: 'Roboto',
            }} >
                PS : A partir du 31 Mars, nous ne pourrons plus rien faire… Profitez-en maintenant


            </Typography>



            <span>
                <ReservationConfirmationDialog
                    isOpen={showConfirmation}
                    onClose={handleCloseConfirmation}
                />
            </span>


        </>
    );
};

export default ResultCard;    